import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  Typography,
  Box,
  useMediaQuery,
  CircularProgress,
  Breadcrumbs,
} from '@mui/material';
import ProductCard from './ProductCard';

const Products = () => {
  const [sofas, setSofas] = useState([]);
  const [chairs, setChairs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const baseUrl = `${window.location.protocol === 'https:' ? 'https' : 'http'}://venturecanadaleather.ca:3001/api/products`;

  useEffect(() => {
    const fetchProducts = async (type, setter) => {
      try {
        const response = await axios.get(`${baseUrl}/${type}`);
        setter(response.data);
      } catch (err) {
        setError(`Error fetching ${type}: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts('Sofa', setSofas);
    fetchProducts('Chairs', setChairs);
  }, [baseUrl]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container className="products-container mt-5">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link to="/">Home</Link>
        <Typography color="text.primary">Products</Typography>
      </Breadcrumbs>

      <Typography variant="h4" align="center" className="mb-4">Our Products</Typography>

      {/* Sofas Section */}
      <Typography variant="h5" align="center" className="mb-2">Sofas</Typography>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} flexWrap="wrap" justifyContent="space-between">
        {sofas.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </Box>

      <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />

      {/* Chairs Section */}
      <Typography variant="h5" align="center" className="mb-2">Chairs</Typography>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} flexWrap="wrap" justifyContent="space-between">
        {chairs.map((product) => (
          <ProductCard key={product._id} product={product} />
        ))}
      </Box>
    </Container>
  );
};

export default Products;
