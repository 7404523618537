import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Container, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './style.css';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen is mobile size

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = (
    <List className="drawer-menu">
      <ListItem button component={NavLink} to="/" onClick={toggleDrawer(false)}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={NavLink} to="/products" onClick={toggleDrawer(false)}>
        <ListItemText primary="Products" />
      </ListItem>
      <ListItem button component={NavLink} to="/about" onClick={toggleDrawer(false)}>
        <ListItemText primary="About Us" />
      </ListItem>
    </List>
  );

  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Container maxWidth="lg" className="navbar-container">
          <div className="navbar-left">
            {isMobile && ( // Show the hamburger menu only on mobile
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                className="menu-icon"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography variant="h6" component="div" className="navbar-title">
                Venture Canada Leather
              </Typography>
            </Link>
          </div>

          {/* Show these buttons only on larger screens */}
          {!isMobile && (
            <div className="navbar-buttons">
              <Button
                color="inherit"
                component={NavLink}
                to="/"
                startIcon={<HomeIcon />}
                className="nav-button"
                activeClassName="active"
              >
                Home
              </Button>
              <Button
                color="inherit"
                component={NavLink}
                to="/products"
                startIcon={<ShoppingBasketIcon />}
                className="nav-button"
                activeClassName="active"
              >
                Products
              </Button>
              <Button
                color="inherit"
                component={NavLink}
                to="/about"
                startIcon={<InfoIcon />}
                className="nav-button"
                activeClassName="active"
              >
                About Us
              </Button>
            </div>
          )}
        </Container>
      </Toolbar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div className="drawer-header">
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        {menuItems}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
